import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

export default function Example(props)
{
    return (
        <Carousel height={props.height}>
            {
                props.items
            }
        </Carousel>
    )
}