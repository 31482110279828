import L from 'leaflet';

import lcm from './leaflet-canvas-markers';

import MiniMap from 'leaflet-minimap';

import 'leaflet-minimap/dist/Control.MiniMap.min.css';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

import 'leaflet-geosearch/dist/geosearch.css'

import { JsonToTable } from "react-json-to-table";

import { renderToString } from 'react-dom/server'

import globals from './globals';

import s from './settings';

lcm(L);

//import 'leaflet/dist/leaflet.css';  //default marker se ni kazal, če je bilo to vključeno tukaj
//https://stackoverflow.com/questions/59970774/dont-generate-map-click-events-when-marker-clicked-in-leaflet?rq=1

export default () => {
  let map = null;
  let layerControl = null;
  let geocoder = null;
  let suLayer = null;
  let noLabelsLayer = null;
  let suCourtLayer = null;
  let osmLayer = null;
  let suPolygons = {};
  let _currentSuPolygonGid = null;
  let mmLayers = {};
  let _view = null;

  let mejeObcinLayer = null;

  const initial = {
    center: [46.139944, 14.85333],
    zoom: 9
  }

  function init (op={}) {
      // create map
      map = L.map('map', {
        center: initial.center,
        zoom: initial.zoom,
        zoomControl: false,
        renderer: L.canvas()
      });

      map.createPane('suSelection');
      map.getPane('suSelection').style.zIndex = 250;

      const osmUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
      const osmAttribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

      const baseMaps = {
        "CartoDB.PositronNoLabels": L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 19
        }),
        "OpenStreetMap": L.tileLayer(osmUrl, {
          attribution: osmAttribution,
          maxZoom: 19
        }),
        "DOF": L.tileLayer.wms("https://storitve.eprostor.gov.si:443/ows-pub-wms/ows?SERVICE=WMS", {
          layers:"SI.GURS.ZPDZ:DOF5",
          attribution: '<a href="http://www.e-prostor.gov.si/dostop-do-podatkov/dostop-do-podatkov/#c501">Geodetska uprava Republike Slovenije</a>',
          maxZoom: 19
        })
      };

      Object.keys(baseMaps).map(key => {
        baseMaps[key].on('add', (e)=>e.target.bringToBack());
      });
      
      const overlays = {
        "Občine":  L.tileLayer.wms(s.geoserver[s.config] + "?", {
          layers: s.instance === 'pravosodje' ? "gi:su_ob_pravosodje_vw" : "gi:su_ob_vw",
          format: "image/png",
          transparent: "true"
        })
      };

      const visibleOverlays = {
        "Območje zajema": {},
        "Meje občin":  L.tileLayer.wms(s.geoserver[s.config] + "?", {
          layers: s.instance === 'pravosodje' ? "gi:su_ob_pravosodje_vw" : "gi:su_ob_vw",
          styles:'dashed_line',
          format: "image/png",
          transparent: "true"
        })
      };

      if (s.instance === 'pravosodje') {
        delete visibleOverlays["Območje zajema"];
      }
      else {
        visibleOverlays["Območje zajema"] = L.tileLayer.wms(s.geoserver[s.config] + "?", {
          layers:"gi:obmocje_zajema",
          format: "image/png",
          transparent: "true"
        });
      }

      mejeObcinLayer=visibleOverlays['Meje občin'];

      layerControl = L.control.layers(baseMaps, visibleOverlays, {collapsed:false}).addTo(map);
      
      document.getElementsByClassName('leaflet-control-container')[0].appendChild(document.getElementsByClassName('leaflet-control-layers')[0]);

      noLabelsLayer = baseMaps['CartoDB.PositronNoLabels'].addTo(map);
      suLayer = overlays['Občine'].addTo(map);
      suCourtLayer = overlays['Občine - sodišča'];

      osmLayer = baseMaps['OpenStreetMap'];

      const osm2 = new L.TileLayer(osmUrl, {minZoom: 0, maxZoom: 13, attribution: osmAttribution});
      new MiniMap(osm2, {
        toggleDisplay: true,
        position: 'topright'
      }).addTo(map);

      const provider = new OpenStreetMapProvider();

      const searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar',
        notFoundMessage: 'Iskanje ni obrodilo sadov.',
        showPopup: true,
        autoClose: false,
        showMarker: true,
        keepResult: true,
        searchLabel: 'Iskanje po imenu kraja ...'
      });

      map.addControl(searchControl);

      document.getElementsByClassName('leaflet-geosearch-bar')[1].style.display = 'none';

      op.onMapClicked && map.on('click', op.onMapClicked);
      op.onPopupOpened && map.on('popupopen', op.onPopupOpened);

      map.on('zoomend', function(event) {
        if (map.getZoom()>initial.zoom) {
          showSuLayer(false);
          mejeObcinLayer.addTo(map);
        }
        else {
          showSuLayer(true);
        }
      });


  }

  function onEachFeature(feature, leafletLayer, layerDefinition) {
    const layerCodeListKey = layerDefinition.table_name;
    // does this feature have a property named popupContent?

    const popupFeatureProperties = {};

    let slika = '';
    let ikoneRanljivihSkupinHtmlImagesJoined = [];
     globals.groups.map(g => {
      if (feature.properties[g.key]) {
        ikoneRanljivihSkupinHtmlImagesJoined.push(`<img src="${g.icons.modro}">`);
      }
      else {
        ikoneRanljivihSkupinHtmlImagesJoined.push(`<img src="${g.icons.sivo}">`);
      }
    });

    ikoneRanljivihSkupinHtmlImagesJoined = ikoneRanljivihSkupinHtmlImagesJoined.join('');

    for (const [key, a] of Object.entries(layerDefinition.attributesByKey)) {
      if (!a._visible) continue;
      if (!feature.properties[key]) continue;
      let value = feature.properties[key];

      if (!value) continue;

      if (a.code_list && Object.keys(a.code_list).length > 0) {
        value = a.code_list[value];
      }
      else if (key.includes('foto')) {
        slika = '<div class="image-gallery-container"></div>';
        continue;
      }
      else if (a.data_type==="date") {  //convert date
        const a = value.split('-');
        value = `${a[2]}. ${a[1]}. ${a[0]}`;
      }

      popupFeatureProperties[a.label || key] = value;
    }

      const maxHeight = 'calc(100vh - 180px)';

      let popup = `<div class="popup-div" style="max-height: ${maxHeight}">
                  <div style="max-height: 200px;" class="ranljive-skupine-popup">
                      ${ikoneRanljivihSkupinHtmlImagesJoined}
                  </div>
                  <div class="popup-feature-props" style="margin-right:5px; max-height: 200px; overflow-y:auto">
                      ${renderToString(<JsonToTable json={popupFeatureProperties} />)}
                  </div>
                  <div style="width:${slika ? 310 : 0}px; height:100%; max-height:200px; margin-left:5px">
                      ${slika}
                  </div>
              </div>`;

      if (s.instance==='pravosodje' && layerCodeListKey === 'objekti_teren') {
          
          const sifko = feature.properties['ko_id'];
          const idsta = feature.properties['st_stavbe'];
          const pravosodje = feature.properties['pravosodje'];

          if (sifko && idsta && pravosodje > 0) {
              popup = popup + `<br><a target="_blank" href="http://dostopnost.javniobjekti.si/prikaz?i_ko=${sifko}&i_obj=${idsta}">Povezava na dostopnost javnih objektov</a>`;
          }
      }

      leafletLayer.bindPopup(popup, {maxWidth : 640});
      leafletLayer.getPopup()._mm_feature = feature;
      leafletLayer.feature = feature;
}

/* https://gist.github.com/geog4046instructor/80ee78db60862ede74eacba220809b64
 * Create a custom icon to use with a GeoJSON layer instead of the default blue
 * marker. This snippet assumes the map object (map) and GeoJSON object
 * (myLayerData) have already been declared.
 */

  const addLayer = (geomData, attributeData, layerDefinition) => {

    const codeList = globals.codeList;

    const attributes = layerDefinition.attributes

    const groupAttributeVisibilityKeys = globals.groups.map(g => g.key.replace('_', ''));

    //attributes visibility
    attributes.map(a => {
      let visible = false;
      for (const gkey of groupAttributeVisibilityKeys) {
        if (a[gkey + '_viden']) {
          visible = true;
          break
        }
      }
      a._visible = visible;
    })

    const {icon, lineColor, lineWidth, dashArray, opacity} = layerDefinition.props;

    const iconImg = icon;

    let features = [];

    const geomDataByGid = {};

    geomData.map(g => {
      geomDataByGid[g.gid] = g.geom;
    })
    
    for (const featureProperties of attributeData) {
      
      const feature = {"type": "Feature", properties: {}};

      const g = geomDataByGid[featureProperties.gid];

      if (!g) continue; //some features were without geometry

      feature.geometry = JSON.parse(g);
      feature.properties = featureProperties;
      features.push(feature);
    }

    let options = {
        filter: function(feature, layer) {
            return true;
        },
        onEachFeature: (feature, layer) => onEachFeature(feature, layer, layerDefinition, map)
    };

    options.style = {};

    if (lineColor) {
        options.style.color = lineColor;
    }

    if (lineWidth) {
        options.style.weight = lineWidth;
    }

    if (dashArray) {
        options.style.dashArray = dashArray;
    }

    if (opacity) {
        options.style.opacity = opacity;
    }

    const layerGroup = L.layerGroup();
      
    features.map(feature => {
      const geom = feature.geometry;

      let layer = null;
      
      if (geom.type === 'MultiPoint' && geom.coordinates && geom.coordinates[0]) {
        const [lng, lat] = geom.coordinates[0];
        layer = L.canvasMarker({lat,lng}, {
          img: {
            url: './icn/'+iconImg,
            size:[21,21],
            offset:{x:0, y:0}
          }
        });

        onEachFeature(feature, layer, layerDefinition, map);

      }
      else if (geom.type === 'MultiLineString') {
        layer = L.geoJSON([feature], options);
      }
       
      layer && layerGroup.addLayer(layer);
      
    });

    layerGroup.addTo(map);

    if (!mmLayers) mmLayers = {};

    mmLayers[layerDefinition.table_name] = layerGroup;

    return layerGroup;

    /*
    
    var vals = Object.values(data).map((rec)=>JSON.parse(rec.value.value))
    console.log(vals)
    
    */
}

const zoomToLayer = (layer) => {
  map.fitBounds(layer.getBounds());
}

const setInitialMapView = () => {
  map.setView(initial.center, initial.zoom);
}

const addSuPolygon = (data, gid) => {

  const layerItem = suPolygons[gid] || L.geoJSON(data, {pane:'suSelection', color:'rgb(21,77,127)', "fillOpacity": 0});
  removeCurrentSuPolygon();
  layerItem.addTo(map);
   
  if (_view) {
    releaseView();
  }
  else {
    map.fitBounds(layerItem.getBounds());
  }

  suPolygons[gid] = layerItem;

  _currentSuPolygonGid = gid;
}

const removeCurrentSuPolygon = () => {
  const gid = _currentSuPolygonGid;
  if (!gid) return;

  suPolygons[gid] && suPolygons[gid].remove();

  _currentSuPolygonGid = null;
}

const zoomPlus = () => {
  map.setZoom(map.getZoom()+1);
}

const zoomMinus = () => {
  map.setZoom(map.getZoom()-1);
}

const releaseView = () => {
  if (!_view) return;
  map.setView(_view.latlng, _view.zoom);
  _view = null;
}

function showSuLayer(show) {
  const _lay = suLayer;

  if (show) {
    _lay.addTo(map) 
    noLabelsLayer.addTo(map);
    map.removeLayer(mejeObcinLayer);   
  }
  else {
    map.removeLayer(_lay)
  }
}

function showOsmLayer(show) {

    if (show) {
      map.removeLayer(noLabelsLayer);
    }

    show ? osmLayer.addTo(map) : map.removeLayer(osmLayer) 
}

  //return Object.freeze({
  return { 
    init,
    layerControl: () => layerControl,
    geocoder: () => geocoder,
    map: () => map,
    addLayer,
    zoomToLayer,
    setInitialMapView,
    zoomPlus,
    zoomMinus,
    showSuLayer: showSuLayer,
    showOsmLayer: showOsmLayer,
    addSuPolygon,
    removeCurrentSuPolygon,
    getCurrentSuPolygonGid: () => _currentSuPolygonGid,
    getMmLayers: () => mmLayers,
    lockView: (latlng, zoom) => {
      _view = {
        latlng:latlng,
        zoom: zoom
      }
    },
    releaseView: releaseView
  };
}