import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import logo from '../assets/01_logotipi/Horizontalni_barvni_offset-01.svg';
import samo_logo from '../assets/01_logotipi/samo_barvni_logo.svg';
import logo_pravosodje from '../assets/02_ikone/Pravosodje_modro.svg';
import facebookIcon from '../assets/02_ikone/Facebook2-01.svg';
import infoIcon from '../assets/02_ikone/Info2-01.svg';
import shareIconSvg from '../assets/02_ikone/Ikone_novo/Share.svg';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...({
      fontFamily: 'Raleway, sans-serif',
      fontSize: '18pt',
      fontStyle: 'italic',
      color: 'rgb(21,77,127)',
      backgroundColor: 'rgb(241,242,242)',
      height: '70px',
      lineHeight: '70px', //vertical center
      marginLeft: 0,
      width: '100%' //`calc(100% - ${drawerWidth}px)`,
    }),
  }));

export default (props) => 
    <AppBar position="fixed" open={true}>
        <div id="header-container">
        <img id="logo" onClick={()=>props.instance==='pravosodje' ? (window.location = window.location.pathname) : window.open("https://www.dostopnost-prostora.si/", "_blank")} style={{height: '70px', marginLeft:'-14px', cursor: 'pointer'}} src={props.instance==='pravosodje' ? logo_pravosodje : logo} className="App-logo" alt="logo" />
        <img id="samo-logo" onClick={()=>props.instance==='pravosodje' ? (window.location = window.location.pathname) : window.open("https://www.dostopnost-prostora.si/", "_blank")} style={{height: '70px', marginLeft:'-14px', cursor: 'pointer'}} src={props.instance==='pravosodje' ? logo_pravosodje : samo_logo} className="App-logo" alt="logo" />
        {
          props.instance === 'pravosodje' ?
          <div id="naslov">
            Pregledovalnik dostopnosti objektov pravosodnih organov<br/>
              <span style={{"fontSize": "0.75em"}}>
                na območju Republike Slovenije za funkcionalno ovirane osebe
              </span>
          </div>
          :
          <div id="naslov">
              Javni pregledovalnik dostopnosti prostora
          </div>
        }
        <div id="header-controls">
            <div style={{marginRight: '15px'}}>
                {props.AutoCompleteCombo}
            </div>
            <div id="icon-buttons">
              <span>
                <IconButton onClick={()=>props.onShareIconClicked && props.onShareIconClicked()}>
                    <img style={{height:'32px'}} src={shareIconSvg} />
                </IconButton>
                {
                    props.instance === 'pravosodje' ?  null : <span>
                    <IconButton onClick={()=>props.onOpenAboutIconClicked && props.onOpenAboutIconClicked()}>  
                    <img style={{height:'32px'}} src={infoIcon} />
                    </IconButton>
                    <IconButton onClick={()=>window.open("https://www.facebook.com/multimodalnamobilnost", "_blank")}>  
                    <img style={{height:'32px'}} src={facebookIcon} />
                    </IconButton>
                    </span>
                }
              </span>
            </div>
        </div>
        </div>
        {
            props.loading && <div style={{color:"rgb(21,77,127)"}}>
            <LinearProgress color="inherit"/>
            </div>
        }
    </AppBar>