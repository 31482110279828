import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  fontFamily: 'Raleway, sans-serif',
  fontSize: '11pt'
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  
  const handleClose = () => {
    props.onClose();
  };

  return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <BootstrapDialogTitle style={{fontFamily:'Raleway, sans-serif'}} id="customized-dialog-title" onClose={handleClose}>
          O projektu
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <div>
            Pregledovalnik dostopnosti prostora za ranljive skupine je spletno orodje, ki omogoča uporabnikom brezplačen vpogled in prenos prostorskih podatkov o mobilnosti ter dostopnosti zunanjega grajenega prostora za gibalno ovirane, slepe in slabovidne, gluhe in naglušne ter starejše osebe v različnih občinah po Sloveniji. Pregledovalnik kot podlago uporablja podatkovno bazo,  v katero so vključene tiste občine, ki so pristopile k projektu z dogovorom. Namenjen je zlasti občinam, organizacijam, podjetjem in ostalim deležnikom, ki se ukvarjajo s pripravo tehnoloških ali prostorskih rešitev za omogočanje varne in samostojne mobilnosti ranljivih udeležencev v prometu, kakor tudi posameznikom.
            <br/><br/>
            Pregledovalnik je rezultat projekta »Omogočanje multimodalne mobilnosti oseb z različnimi oviranostmi«, ki ga financira Ministrstvo za okolje, podnebje in energijo Republike Slovenije, izvaja ga Geodetski inštitut Slovenije.  
            <br/><br/>
            Več informacij o projektu je na voljo na spletni strani <a href="http://dostopnost-prostora.si/" target="_blank">www.dostopnost-prostora.si</a>.
              <br/>
                <div style={{"textAlign": "right", "marginRight":0, "marginTop":'0.75em'}}>Produkcija: 2019, <a href="http://www.gis.si" target="_blank">Geodetski inštitut Slovenije (GI)</a></div>
                <img src="./logo_gi.png" style={{"display":"block", "margin": "auto", "marginTop": "5px"}} />
            </div>
         
        </DialogContent>
      </BootstrapDialog>
  );
}
