import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

import AutoCompleteCombo from './AutoCompleteCombo.js';

import utils from '../utils';

import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import globals from '../globals';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const LayerListDialog = styled('div')({
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    padding:'30px',
    fontSize:'13px',
    color: 'rgb(21,77,127)',
    borderRadius: '4px'
  });

  const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'rgb(21,77,127)',
      '&:hover': {
        backgroundColor: alpha('rgb(21,77,127)', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgb(21,77,127)',
    },
  }));
  //.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked 

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    fontFamily: 'Raleway, sans-serif',
    fontSize: '11pt'
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{display:'flex'}}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

export default (props) => {

  //count all objects

  if (!(globals.attributesDefinitionByLayer && globals.spatialNames)) return null;

  const handleClose = () => {
    props.onClose();
  };

  const menuItemsByTableName = {};

  const selectedGroup = globals.groups.find(g => g.key === props.selectedGroupKey);

  const tableNames = Object.keys(globals.attributesDefinitionByLayer).map(key => {
    const item = globals.attributesDefinitionByLayer[key];
    menuItemsByTableName[item.table_name] = item;
    item.count = 0;
    return item.table_name
  });

  globals.spatialNames.map(d => {
    tableNames.map(tname => {
      if (d[selectedGroup.key] && d[selectedGroup.key][tname]) {
        let c = 0;
        if (props.selectedSpatialUnitGid) {
          c = d.gid == props.selectedSpatialUnitGid ? parseInt(d[selectedGroup.key][tname]) : 0 
        }
        else {
          c = parseInt(d[selectedGroup.key][tname]);
        }

        menuItemsByTableName[tname].count += c;
      }
    });
  });

    return (
      <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={true}
        >
          <BootstrapDialogTitle style={{fontFamily:'Raleway, sans-serif'}} id="customized-dialog-title" onClose={handleClose}>
            <img className="modro" style={{height:'30px'}} src={selectedGroup.icons.modro} />
            <span>&nbsp;</span>
            <span>Izbira slojev za prikaz</span>
          </BootstrapDialogTitle>
      <DialogContent dividers>
        <div>
        {
           !props.selectedSpatialUnitGid ?
            <div>
              <div style={{marginBottom:'1em'}}>Izberite občino za prikaz podatkov</div>
              <AutoCompleteCombo selectedItem={null} onItemSelected={props.onSpatialUnitSelected} items={globals.spatialNames}></AutoCompleteCombo>
            </div>
          :
          <LayerListDialog>
          <div style={{marginTop:'10px'}}>
          {Object.keys(props.items).map((key, index) => {
            const item = props.items[key];
            return (<div key={selectedGroup.key + '_' + index} style={{display:'flex', alignItems:'center'}}>
              <div style={{display:'flex', alignItems: 'center'}}>
                <IconButton style={{marginRight:'10px', padding:'2px'}}><img src={'./icn/'+item.props.icon}/></IconButton>
                <div style={{marginRight:'72px'}}>{item.label}</div>
              </div>
              <div style={{marginLeft:'auto', display: 'flex',alignItems:'center'}}>
                <div style={{display: 'flex', justifyContent:'flex-end', gap:'3px'}}>
                  {
                    utils.ikoneRanljivihSkupin(globals.groups, props.checked, item.table_name)
                  }
                </div>
                <div style={{display:'flex',alignItems:'center', justifyContent: 'flex-end', width:'120px'}}>
                  ({item.count})
                  <StyledSwitch checked={props.checked[selectedGroup.key][item.table_name]===true} onChange={(event) => props.handleLayerSwitchChange(event, item.table_name, selectedGroup.key)} size="small" {...label} />                
                </div>
              </div>
            </div>);
          })}
          </div>
        </LayerListDialog>
        }
        </div>
      </DialogContent>
      </BootstrapDialog>
    );
  }