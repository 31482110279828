const settings = {
    local: {
        apiUrl: `http://localhost:8087/public/api`,
        email: "multim.api@gis.si",
        password: "9560dh7h67ti58f8578e4fh34as9gjgtu28"
    },
    server: {
        apiUrl: `https://pregledovalnik.dostopnost-prostora.si/multim2/api`,
        email: "multim.api@gis.si",
        password: "9560dh7h67ti58f8578e4fh34as9gjgtu28"
    },
    fotoRoot: 'https://pregledovalnik.dostopnost-prostora.si/multim2/foto/',
    geoserver: {
        local: 'http://localhost:8081/geoserver/gi/wms',
        server: 'https://pregledovalnik.dostopnost-prostora.si/geoserver/gi/wms'
    }
}

settings.instance = window.location.pathname.includes('/pravosodje') ? 'pravosodje' : null;

settings.config = (process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? 'local' : 'server';

export default settings;