/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { styled } from '@mui/material/styles';

const AutocompleteStyled = styled(Autocomplete)({
  '& .MuiInputBase-input': {
    height: '16px',
    fontSize: '15px'
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1em'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: `16px`,
    },
  }
  });

export default function ComboBox(props) {

  const onItemSelected = (event, value) => {
    props.onItemSelected(value);
  }

  let value = null;

  if (props.selectedItem && props.selectedItem.gid) {
    value = {
      gid: props.selectedItem.gid,
      name: props.selectedItem.ob_uime || props.selectedItem.name
    }
  }

  return (
    <AutocompleteStyled
      style={props.style}
      id="combo-box-demo"
      size="small"
      value={value}
      options={props.items.map(sn => ({name: sn.ob_uime, gid: sn.gid}))}
      getOptionLabel={(option) => option.name}
      onChange={onItemSelected}
      className="omeji-zapise"
      renderInput={(params) => <TextField {...params} label="Občina" size="small" variant="outlined" />}
    />
  );
}