import * as React from 'react';
import L from 'leaflet';
import ReactDOM from 'react-dom';
import {useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import Multim2AppBar from './components/AppBar';

import List from '@mui/material/List';

import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AutoCompleteCombo from './components/AutoCompleteCombo';

import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import downloadModroSvg from './assets/02_ikone/Download_modro-01.svg';
import downloadBeloSvg from './assets/02_ikone/Download-01.svg';

import logotipGIS from './assets/01_logotipi/logotip_GIS_barvni-01.svg';
import logotipMOPE from './assets/01_logotipi/logotip_MOPE_dolgi-01.png';
import logotipMP from './assets/01_logotipi/logotip_MP.svg';

import TextField from '@mui/material/TextField';

import zoomPlus from './assets/02_ikone/Plus-01.svg';
import zoomMinus from './assets/02_ikone/Minus-01.svg';
import zoomPlusHover from './assets/02_ikone/Ikone_novo/Plus_modro-01.svg';
import zoomMinusHover from './assets/02_ikone/Ikone_novo/Minus_modro.svg';
import layersSvg from './assets/02_ikone/Layers2-01.svg';
import layersSvgHover from './assets/02_ikone/Layers2_negativ-01.svg';

import lupaSvg from './assets/02_ikone/Search-01-01.svg';
import lupaSvgHover from './assets/02_ikone/Search_belo-01.svg';


import map from './map';

import copy from 'copy-to-clipboard';

import axios from 'axios'; 
import s from './settings';

import LayerListDialog from './components/LayerListDialog';

import globals from './globals';
import utils from './utils';

import AboutDialog from './components/AboutDialog';

import SimpleSnackbar from './components/SnackBar';

import ImageCarousel from './components/ImageCarousel';

axios.defaults.baseURL = s[s.config].apiUrl

const drawerWidth = 210;
const closedDrawerWidth = 65;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: closedDrawerWidth
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Footer = styled('div', {shouldForwardProp: prop => prop !== 'open'})(({ theme, open }) => ({
  height: '40px', 
  position: 'fixed', 
  bottom:0,
  right:0,
  backgroundColor:'rgb(241,242,242)',
  width: `calc(100% - ${open ? drawerWidth : closedDrawerWidth}px)`,
  display: 'flex',
  justifyContent: 'right',
  img:{
    height:'36px'
  }
}));

export const SearchField0 = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const SearchField = styled(TextField)({
  fieldset: {
    borderRadius: '50px'
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

const MapDiv = styled('div')({
  width: `calc(100% - ${closedDrawerWidth}px)`,
  height: `calc(100vh - 70px - 40px)`,
  position: 'fixed',
  left: closedDrawerWidth,
  top: '70px',
  background: 'grey'
});

const MapIconButton = styled(IconButton)(
  (props) => ({
  height: '32px',
  width: '32px',
  img: {
    height: '32px'
  },
  boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  ':hover .img-base': {
    display: 'none'
  },
  ':hover .img-hover': {
    display: 'inherit'
  },
  '.img-base': {
    display: props._selected ? 'none' : 'inherit'
  },
  '.img-hover': {
    display: props._selected ? 'inherit' : 'none'
  }
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    //whiteSpace: 'nowrap',
    fontFamily: 'Raleway, sans-serif',
    '.MuiDrawer-paper': {
      backgroundColor: 'rgb(21,77,127)',
      color: 'white'
    },
    fontWeight: 500,
    fontSize: '14pt',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const selectedMixin = (theme) => (
  {
    backgroundColor:'white',
    color: 'rgb(21,77,127)'
  }
);

const CustomListItem = styled(MuiListItem)(
  (props) => 
  {
  return {
    padding:0, 
    ...(props._selected && selectedMixin()),
    //borderTop:'solid 1px', 
    //borderColor:'#E0E0E0',
    ':hover':{
      ...selectedMixin()
    },
    ':hover .belo': {
      display: 'none'
    },
    ':hover .modro': {
      display: 'inherit'
    },
    '.modro': {
      display: props._selected ? 'inherit' : 'none'
    }
  }
}
);

let _map = null;

export default function MiniDrawer() {

  //let selectedSpatialUnitGid = null;

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [checked, setChecked] = React.useState({});

  const [selectedGroup, setSelectedGroup] = React.useState({});

  const [selectedSpatialUnitGid, setSelectedSpatialUnitGid] = React.useState();

  const [layersToLoad, setLayersToLoad] = React.useState([]);

  const [layersButtonSelected, setLayersButtonSelected] = React.useState(0);

  const [searchButtonSelected, setSearchButtonSelected] = React.useState(0);

  const [openAbout, setOpenAbout] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [deepLink, setDeepLink] = React.useState('');

  const [onCopy, setOnCopy] = React.useState(false);

  const [error, setError] = React.useState('');

  const toggleDrawerOpen = () => {
      setOpen(!open);
  }

  const items2 = [
  {
    text: 'Prevzem podatkov',
    key: 'PREVZEM_PODATKOV',
    icons: {
      belo: downloadBeloSvg,
      modro: downloadModroSvg,
    },
    onClick: () => {
      axios.get('export-geopackage/'+selectedSpatialUnitGid, { responseType: 'blob' })
      .then(response => {
        // Create a URL for the blob response
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `multim2.zip`);
        link.setAttribute('target', '_blank'); // For some browsers
        link.setAttribute('rel', 'noopener noreferrer'); // For security

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        // Handle error
        console.error('Error downloading GeoPackage:', error);
      });
    }
  }
  ];
  
  useEffect(() => {
    _map = map();
    _map.init({
      onMapClicked: e => {
        
        document.getElementsByClassName('leaflet-control-layers')[0].style.display = 'none';
        setLayersButtonSelected(0);

        axios.get('/spatial/su?latlng='+JSON.stringify(e.latlng)).then(function (res) {
          
          if (res.data && res.data.gid) {
            const gid = res.data.gid;

            if (globals.spatialNamesByGid[gid]) {
              const sudata = globals.spatialNamesByGid[gid];
              if (!sudata.geom) {
                sudata.geom = JSON.parse(res.data.geom);
              }
            }
            else {
              globals.spatialNamesByGid[gid] = res.data;
            }

            onSpatialUnitSelected(globals.spatialNamesByGid[gid]);

          }
        });
      },
      onPopupOpened: e => {
        //const imgs = e.popup.getElement().getElementsByTagName('img');
        const containers = e.popup.getElement().getElementsByClassName('image-gallery-container');
        if (containers.length > 0) { //show photos
          const container = containers[0];
          const foto = e.popup._mm_feature.properties.foto;
          const slike = foto.split(',').map(s => s.trim().toLowerCase());

          const maxHeight = 'calc(100vh - 180px)';

          if (slike.length === 1) {
            container.innerHTML = `<img style="max-height:${maxHeight};height:200px;max-width:310px" src="${s.fotoRoot}${slike[0]}" />`;
          }
          else {
            container.style.height='207px';
            const items = slike.map((slika,inx) => <img key={`img${inx}`} style={{maxHeight: maxHeight, height:'200px',maxWidth:'310px'}} src={s.fotoRoot + slika} />);
            ReactDOM.render(<ImageCarousel height={180} items={items}></ImageCarousel>, containers[0]);
          }
        }

        
      
        /*
        for (const img of imgs) {
          img.addEventListener('click', () => {
            
          })
        }*/
      }
    }); //_map.init

    const control = document.getElementsByClassName('leaflet-control-layers')[0];
    control.onmouseleave = function() {
      control.style.display = 'none';
      setLayersButtonSelected(0);
    }

    const instanceParameters = {};

    const p = {};

    if (s.instance==='pravosodje') {
      p.params = {};
      p.params.instance = s.instance;
      const objectId = utils.getQueryParameter('id', false);
      if (objectId) {
        p.params.object_id = objectId;
      }
    }

    axios.all([axios.get('/spatial/names-by-group',p), axios.get( '/attributes-definition'), axios.get( '/groups')]).then(
      axios.spread(async (spatialNames, attributesDefinition, groups) => {

      const codeList = {};

      globals.spatialNames = spatialNames.data;

      const spatialNamesByGid = {};

      globals.spatialNames.map(sn => {
        spatialNamesByGid[sn.gid] = sn;
      });

      globals.groups = s.instance ==='pravosodje' ? groups.data.filter(g => g.key!=='_starostniki') : groups.data;

      globals.spatialNamesByGid = spatialNamesByGid;

      const attributesDefinitionByLayer = {};

      attributesDefinition.data.map(ld => {
        if (s.instance==='pravosodje' && ['fizicne_ovire_zacasne','tocke'].indexOf(ld.table_name)!==-1) return;
        ld.props = (ld.props && JSON.parse(ld.props)) || {};
        ld.props.icon = 'Ikone_novo/' + ld.table_name + '.svg';
        ld.attributes = ld.attributes.sort(function (a, b) {
          return a.weight - b.weight;
        });

        ld.attributes.map(a => {
          if (a.code_list) {
            a.code_list = JSON.parse(a.code_list);
          }
        });

        const attributesByKey = {};
        ld.attributes.map((a)=>attributesByKey[a.cname] = a);
        
        ld.attributesByKey = attributesByKey;

        attributesDefinitionByLayer[ld.table_name] = ld;
      });

      globals.attributesDefinitionByLayer = attributesDefinitionByLayer;

      globals.codeList = codeList;

      const initialChecked = {};

      globals.groups.map(g=>initialChecked[g.key]={});

      setChecked(initialChecked);

      const objectId = utils.getQueryParameter('id', false);

      const qlids = objectId ? null : utils.getQueryParameter('lids');

      const defaultLayers = {};
      
      let instanceGID = null;
      
      if (objectId) {
        const o = globals.spatialNames.find(sn => {
          for (const group of globals.groups) {
            if (sn[group.key]) {
              return true;
            }
          }
          return false;
        });
        instanceGID = o && o.gid;
      }

      setLayersToLoad(Object.keys(globals.attributesDefinitionByLayer));
      setSelectedSpatialUnitGid(instanceGID);

    }))
    .catch(()=>{})
    .then(()=>{
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedSpatialUnitGid && layersToLoad.length>0) {
      const objectId = utils.getQueryParameter('id', false);

      const loadedLayers = {};

      let numberOfLayersToLoad = 0;
      let numberOfLoadedLayers = 0;

      for (const layerKey of layersToLoad) {
        numberOfLayersToLoad++;
        
        loadLayer(layerKey, () => {
          numberOfLoadedLayers++;
          setInitialChecked(layerKey, loadedLayers);
          //
          
          if (numberOfLayersToLoad === numberOfLoadedLayers) {
            setChecked(loadedLayers);
            setDeepLinkMapView(loadedLayers, selectedSpatialUnitGid, objectId);
          }
        },objectId);
      }
    }
  }, [selectedSpatialUnitGid, layersToLoad]);


  function setDeepLinkMapView(loadedLayers={}, instanceGID=null, objectId = null) {
    
    const z = utils.getQueryParameter('z');
    const lat = utils.getQueryParameter('lat');
    const lng = utils.getQueryParameter('lng');
    if (z && lat && lng) {
      _map.lockView({lat,lng},z)
    }

    const gid = instanceGID || utils.getQueryParameter('gid');
    if (gid) {
      onSpatialUnitSelected({gid:parseInt(gid)},()=>{
        instanceGID && zoomToInstanceObjects(objectId);
      });
    }
    else {
      _map.releaseView();
    }
  }

  const zoomToInstanceObjects = (objectId = null) => {

      const filteredLayersBounds = [];

      const polyline = L.polyline([]);

      Object.keys(globals.leafletLayers).map(layerKey => {
          Object.keys(globals.leafletLayers[layerKey]).map(obId => {
            if (layerKey === 'linije') {
              const layers = globals.leafletLayers[layerKey][obId].getLayers();
              if (layers.length > 0) {
                layers.map(layer=>filteredLayersBounds.push(layer.getBounds()));
              }
            }
            else {
              const layer = globals.leafletLayers[layerKey][obId];
              if (layer) {
                const pnts = layer.getLayers() || [];
                pnts.map(pnt => polyline.addLatLng(pnt.getLatLng()));
              }
            }
          });
      });

      if (polyline.getLatLngs().length > 0) {
        filteredLayersBounds.push(polyline.getBounds());
      }
        
      let minLat = 90;
      let minLng = 180;
      let maxLat = -90;
      let maxLng = -180;

      filteredLayersBounds.map(bounds => {
        const ne = bounds.getNorthEast();
        
        if (ne.lat > maxLat ) maxLat = ne.lat;
        if (ne.lng > maxLng) maxLng = ne.lng;

        const sw = bounds.getSouthWest();
        
        if (sw.lat < minLat ) minLat = sw.lat;
        if (sw.lng < minLng) minLng = sw.lng;

      });

      _map.map().flyToBounds([[minLat, minLng], [maxLat, maxLng]]);  //fitBounds

      const objektiTeren = globals.leafletLayers && globals.leafletLayers.objekti_teren && globals.leafletLayers.objekti_teren[selectedSpatialUnitGid];
      console.log('objektiTeren', objektiTeren, globals.leafletLayers)
      if (objektiTeren) {
        const layers = objektiTeren.getLayers() || [];
        const target= layers.find(lay => (lay.feature.properties.ko_id && lay.feature.properties.st_stavbe) && (lay.feature.properties.ko_id + '-' + lay.feature.properties.st_stavbe) === objectId);
        target && target.openPopup();
      }
  }

  const onLayerGroupClicked = (group) => {
  
    if (group.key === 'PREVZEM_PODATKOV') {
      group.onClick();
    }

    setSelectedGroup(group);
  }

  const handleLayerSwitchChange = (event, layerKey, groupKey) => {
    const _checked = event.target.checked;

    const checkedObject = Object.assign({}, checked);
    checkedObject[groupKey][layerKey] = _checked;
    setChecked(checkedObject);
    
    if (_checked) {
      loadLayer(layerKey);
    }
    else {
      removeLayer(groupKey, layerKey);
    }
  };

  const removeLayer = (_groupKey, layerKey) => {

    const layerKeyInOtherGroups = Object.keys(checked).filter(groupKey => groupKey !== _groupKey && checked[groupKey][layerKey]);

    if (layerKeyInOtherGroups.length === 0) {
      const leafletMap = _map.map();
      Object.keys(globals.leafletLayers[layerKey]).map(obId => {
        const layer = globals.leafletLayers[layerKey][obId];
        layer && leafletMap.removeLayer(layer);
      });
    }
      
  }

  const setInitialChecked = (layerKey, initialChecked, checked = true) => {
    globals.groups.map(g => {
      const groupKey = g.key;
      if (!initialChecked[groupKey]) initialChecked[groupKey] = {};
      initialChecked[groupKey][layerKey]=checked;
    });
  }

  const checkAllLayers = (checked = true) => {

    if (!globals.attributesDefinitionByLayer) return;

    const initialChecked = {};
    
      Object.keys(globals.attributesDefinitionByLayer).map(layerKey=>{
        setInitialChecked(layerKey, initialChecked, checked);

        if (checked) {
          loadLayer(layerKey);
        }
        
      });
    
    setChecked(initialChecked);
  }

  useEffect(() => {
    if (selectedSpatialUnitGid) {
      checkAllLayers();
    }
    else {
      const leafletMap = _map.map();
      globals.leafletLayers && Object.keys(globals.leafletLayers).map(layerKey => {
        Object.keys(globals.leafletLayers[layerKey]).map(obId => {
          const layer = globals.leafletLayers[layerKey][obId];
          layer && leafletMap.removeLayer(layer);
        });
      });

      checkAllLayers(false);
    }
  }, [selectedSpatialUnitGid]);

  const handleSpatialUnitSelection = (gid = null, callback=null) => {

    const currentSuPolygonGid = _map.getCurrentSuPolygonGid();

    const previousGid = currentSuPolygonGid || null;
    
    if (gid && gid == previousGid) return;

    if (gid) {
      const su = globals.spatialNamesByGid[gid];
      const geom = su && globals.spatialNamesByGid[gid].geom;
      geom && _map.addSuPolygon(geom, gid);
      _map.showSuLayer(false);
      _map.showOsmLayer(true);
    }
    else {
      _map.removeCurrentSuPolygon();
      _map.setInitialMapView();
      _map.showSuLayer(true);
      _map.showOsmLayer(false);
    }

    setSelectedSpatialUnitGid(gid);

    callback && callback(gid);

  }

  const filterFeatures = (layerKey) => {

    const leafletMap = _map.map();

    const get_ob_gid = layer => layer.feature && layer.feature.properties && layer.feature.properties.ob_gid;
    
    const gid = selectedSpatialUnitGid;

    globals.leafletLayers && globals.leafletLayers[layerKey] && Object.keys(globals.leafletLayers[layerKey]).map(obId=> {
      globals.leafletLayers[layerKey][obId].getLayers().map(layer => {
        let addLayer = false;

        if (gid) {
          if (get_ob_gid(layer) == gid) {
            addLayer = true;
          }
          else if (layer.getLayers) {
            const layers =  layer.getLayers();
            if (layers.length > 0) {
              if (get_ob_gid(layers[0]) == gid) {
                addLayer = true;
              }
            }
          }
        }
        else {
          addLayer = true; //gid===null
        }

        if (addLayer) {
          const ll = layer.addTo(leafletMap);
          if (layerKey !== 'linije') {
            ll.bringToFront();
          }
          else {
            ll.bringToBack();
          }
        }
        else {
          layer.remove();
        }

      });
    });
  }

  const onSpatialUnitSelected = (su, callback) => {
    const gid = su && su.gid;

    if (gid) {
      if (globals.spatialNamesByGid[gid].geom) {
        handleSpatialUnitSelection(gid, callback);
      }
      else {
        axios.get(`/spatial/geom/${gid}`).then(res => {
          globals.spatialNamesByGid[gid].geom = JSON.parse(res.data.geom);
          handleSpatialUnitSelection(gid, callback);
        });
      }
    }
    else {
      handleSpatialUnitSelection(null, callback);
    }
  }

  const loadLayer = (layerKey, _onLayerLoaded=null,objectId='') => {
    if (!selectedSpatialUnitGid) return;
    const onLayerLoaded = () => {
        filterFeatures(layerKey);
        _onLayerLoaded &&_onLayerLoaded(layerKey);
    }

    if (globals.leafletLayers && globals.leafletLayers[layerKey] && globals.leafletLayers[layerKey][selectedSpatialUnitGid]) {
      _map.map().addLayer(globals.leafletLayers[layerKey][selectedSpatialUnitGid]);
      onLayerLoaded && onLayerLoaded(layerKey);
      return;
    }
    
    setLoading(true);

    const p = {};

    if (s.instance === 'pravosodje') {
      p.params = {};
      p.params.instance = s.instance;
      p.params.object_id = objectId;
    }

    let ob_id = '';

    if (selectedSpatialUnitGid) {
      ob_id = '/' + globals.spatialNamesByGid[selectedSpatialUnitGid].ob_id
    }

    axios.all([ axios.get( `/geom/${layerKey}${ob_id}`, p), axios.get( `/attribute-values/${layerKey}${ob_id}`, p )]).then(
      axios.spread( (geomResponse, attributeResponse ) => {

        const geomData = groupDataByProperty(geomResponse.data, 'ob_id');
        const attrData = groupDataByProperty(attributeResponse.data, 'ob_id');

        const layerDefinition = globals.attributesDefinitionByLayer[layerKey];

        for (const obId of Object.keys(geomData)) {
          if (!globals.leafletLayers) globals.leafletLayers = {};
          if (!globals.leafletLayers[layerKey]) globals.leafletLayers[layerKey] = {};
          const layer = globals.leafletLayers[layerKey][selectedSpatialUnitGid] = _map.addLayer(geomData[obId], attrData[obId], layerDefinition);
        }
        onLayerLoaded && onLayerLoaded(layerKey);
      })
    )
    .catch(error => {
      console.log(error);
      setError(error.message);
    })
    .then(() => {
      console.log('setLoading = false')
      setLoading(false);
    } //always executed
    );
  }

  function groupDataByProperty(data, p) {
    
    const res = {};

    data.map(o=>{
      if (!res[o[p]]) res[o[p]] = [];
      res[o[p]].push(o);
    });

    return res;
  }

  const onOpenAboutIconClicked = () => {
    setOpenAbout(true);
  }

  const onShareIconClicked = () => {
    const map = _map.map();
    const {lat, lng} = map.getCenter();
    const z = map.getZoom();

    const gid = _map.getCurrentSuPolygonGid();

    const p = [`lat=${lat}`,`lng=${lng}`,`z=${z}`];

    const lids = {};

    Object.keys(checked).map(groupKey => {
      Object.keys(checked[groupKey]).map(layerKey => {
        if (checked[groupKey][layerKey]) {
          if (!lids[groupKey]) {
            lids[groupKey] = [];
          }
          lids[groupKey].push(layerKey);
        }
      });
    })

    p.push('lids='+JSON.stringify(lids));

    gid && p.push(`gid=${gid}`);

    const _loc = window.location;

    setDeepLink(`${_loc.origin}${_loc.pathname}?p=${btoa(p.join('&'))}`);
  }

  const items = globals.groups || [];

  return (
    <div>
      <Multim2AppBar {...loading} instance={s.instance} onShareIconClicked={onShareIconClicked} onOpenAboutIconClicked = {onOpenAboutIconClicked}
        AutoCompleteCombo = {<AutoCompleteCombo selectedItem={(selectedSpatialUnitGid && globals.spatialNamesByGid[selectedSpatialUnitGid]) || null} onItemSelected={onSpatialUnitSelected} items={globals.spatialNames}></AutoCompleteCombo>}
      >
      </Multim2AppBar>
      {openAbout && <AboutDialog onClose={()=>setOpenAbout(false)}></AboutDialog>}
      
      <Drawer variant="permanent" open={open}>
        <DrawerHeader className="drawer-header">
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {
          items.map((item, index) => {

            item.icons = {
              belo: 'icn/assets/' + item.key+'_belo.svg',
              modro: 'icn/assets/' + item.key+'_modro.svg',
              sivo: 'icn/assets/' + item.key+'_sivo.svg'
            }

            const selected = selectedGroup.key === item.key ? 1 : 0;  //drugače je iz styled componente vračal Warning: Received `false` for a non-boolean attribute

            let menuItems = globals.attributesDefinitionByLayer || [];

            return (
            <div key={'upper' + index} style={{display: 'flex'}}>
              {
                Object.keys(menuItems).length > 0 &&
              <React.Fragment>
              <CustomListItem className="drawer-list-item" button _selected={selected} key={item.key} onClick={() => onLayerGroupClicked(item)}>
                <ListItemIcon style={{width:'65px', display: 'flex', justifyContent: 'center'}}>
                    {selected!==1 && <img className="belo" style={{height:'30px'}} src={item.icons.belo} />}
                    <img className="modro" style={{height:'30px'}} src={item.icons.modro} />
                </ListItemIcon>
                <div className={"menu-item-label"}>{item.label}</div>
              </CustomListItem>
              {selected === 1 && 
                <LayerListDialog 
                  onSpatialUnitSelected={onSpatialUnitSelected}
                  selectedGroupKey={selectedGroup.key}
                  selectedSpatialUnitGid={selectedSpatialUnitGid}
                  onClose={()=>setSelectedGroup({})} 
                  checked = {checked} 
                  handleLayerSwitchChange = {handleLayerSwitchChange} items = {menuItems} >
                </LayerListDialog>}
              </React.Fragment>
            }
            </div>
          );
          })}
        </List> 

        <List style={{marginTop:'auto'}}>
          {items2.map((item, index) => {
            const selected = 0;
            return (
              <div key={'lower' + index} style={{display: 'flex'}}>
              <CustomListItem disabled = {selectedSpatialUnitGid ? false : true} button _selected={selected} key={item.text} onClick={() => onLayerGroupClicked(item)}>
                <ListItemIcon style={{width:'65px', display: 'flex', justifyContent: 'center'}}>
                    {selected!==1 && <img className="belo" style={{height:'30px'}} src={item.icons.belo} />}
                    <img className="modro" style={{height:'30px'}} src={item.icons.modro} />
                </ListItemIcon>
                <div>{item.text}</div>
              </CustomListItem>
            </div>
            );
            })}
        </List>

        

      </Drawer>

      <MapDiv id="map"></MapDiv>
      <div className="map-controls">
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {<MapIconButton _selected={searchButtonSelected} style={{marginBottom: "10px"}} onClick ={
                () => {
                  const control = document.getElementsByClassName('leaflet-geosearch-bar')[1];

                  if (searchButtonSelected == 1) {
                    control.style.display = 'none';
                    setSearchButtonSelected(0);
                  }
                  else {
                    control.style.display = 'inherit';
                    setSearchButtonSelected(1);
                  }
                }
              }>  
                <img className="img-base" src={lupaSvg} />
                <img className="img-hover" src={lupaSvgHover} />
              </MapIconButton>}

              {<MapIconButton _selected={layersButtonSelected} style={{marginBottom: "10px"}} onClick={
                ()=>{
                  const control = document.getElementsByClassName('leaflet-control-layers')[0];
                  if (layersButtonSelected == 1) {
                    control.style.display = 'none';
                    setLayersButtonSelected(0);
                  }
                  else {
                    control.style.display = 'inherit';
                    setLayersButtonSelected(1);
                  }
                }
              }>  
                <img className="img-base" src={layersSvg} />
                <img className="img-hover" src={layersSvgHover} />
              </MapIconButton>
              }
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <MapIconButton style={{marginBottom: "10px"}} onClick={()=>_map.zoomPlus()}>  
                <img className="img-base" src={zoomPlus} />
                <img className="img-hover" src={zoomPlusHover} />
              </MapIconButton>
              <MapIconButton  onClick={()=>_map.zoomMinus()}>  
                <img className="img-base" src={zoomMinus} />
                <img className="img-hover" src={zoomMinusHover} />
              </MapIconButton>
            </div>
      </div>
      <Footer className="map-footer">
        {
          s.instance === 'pravosodje' ? 
          <img src={logotipMP}/> :
          <img style={{height:'52px', marginTop:'-5px'}} src={logotipMOPE}/>
        }
        <img style={{marginLeft:'70px', marginRight:'22px'}} src={logotipGIS} />
      </Footer>
      {error && <SimpleSnackbar message={error}></SimpleSnackbar>}
      {deepLink && <SimpleSnackbar 
      
        anchorOrigin = {{ vertical: 'top', horizontal: 'right' }}

        button={
            {
              onClick: () => {
                const res = copy(deepLink);
                if (res === true) {
                  setOnCopy(true);
                  setTimeout(()=>setOnCopy(false),3000)
                }
              },
              label: 'KOPIRAJ'
            }
          }
        message={deepLink}></SimpleSnackbar>
      }
      {onCopy && <SimpleSnackbar anchorOrigin = {{ vertical: 'top', horizontal: 'right' }} message={"Povezava je bila skopirana na odložišče."}></SimpleSnackbar>}
    </div>
  );
}
